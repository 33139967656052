const LANDING_URL = "https://coindisco.com";

export const LANDING_PAGES = {
  privacy: `${LANDING_URL}/privacy`,
  terms: `${LANDING_URL}/terms`,
  points: `${LANDING_URL}/points`,
};

export const enum Pages {
  home = "/",
  coins = "/coins",
  coin = "/coin",
  buy = "/buy",
  insights = "/insights",
  points = "/points",
  transactions = "/transactions",
}
